import {lazy, useEffect, useState, useTransition} from 'react';
import {Suspense} from './utils/wrapped-suspense';
import Html from './Html';
import Spinner from './Spinner';
import Layout from './Layout';
import {Route, Routes} from 'react-router-dom';
import {ErrorPage} from './pages/ErrorPage';
import ErrorBoundary from './components/ErrorBoundary';

const Browse = lazy(
  () =>
    import(
      './Browse'
      /* webpackChunkName: "browse" */
      /* webpackPrefetch: true */
    ),
);
const MappingTools = lazy(
  () =>
    import(
      './MappingTools'
      /* webpackChunkName: "mapping" */
      /* webpackPrefetch: true */
    ),
);
const About = lazy(
  () =>
    import(
      './pages/About'
      /* webpackChunkName: "about" */
      /* webpackPrefetch: true */
    ),
);
// const Academy = lazy(
//   () =>
//     import(
//       /* webpackChunkName: "academy" */
//       /* webpackMode: "lazy" */
//       './Academy'
//     ),
// );
const Home = lazy(
  () =>
    import(
      './Home'
      /* webpackChunkName: "home" */
      /* webpackPrefetch: true */
    ),
);
const Examples = lazy(
  () =>
    import(
      './pages/Examples'
      /* webpackChunkName: "examples" */
      /* webpackPrefetch: true */
    ),
);
declare var window;

export default function App({assets = typeof window !== 'undefined' ? window['assetManifest'] : {}}) {
  return (
    <Html assets={assets} title="LINCD - Linked Interoperable Code & Data">
      {/*<Suspense fallback={<Spinner />}>*/}
      <ErrorBoundary FallbackComponent={Error}>
        <Content />
      </ErrorBoundary>
      {/*</Suspense>*/}
    </Html>
  );
}

function Content() {
  let [sidebarIsOpen, setSidebarIsOpen]: [boolean, any] = useState(false);

  const [isPending, startTransition] = useTransition();
  useEffect(() => {
    if (window.innerWidth < 768) {
      startTransition(() => setSidebarIsOpen(false));
    }
  }, []);

  return (
    <Layout sidebarIsOpen={sidebarIsOpen} setSidebarIsOpen={setSidebarIsOpen}>
      <div className="content">
        <Routes>
          <Route
            path="/"
            element={
              <ErrorBoundary fallback={<ErrorPage />}>
                <Suspense fallback={<Spinner />}>
                  <Home />
                </Suspense>
              </ErrorBoundary>
            }
          />
          {/*<Route*/}
          {/*  path="https://docs.lincd.org"*/}
          {/*   element={*/}
          {/*     <Suspense fallback={<Spinner />}>*/}
          {/*       <Docs />*/}
          {/*     </Suspense>*/}
          {/*   }*/}
          {/*/>*/}
          <Route
            path="/browse/*"
            element={
              <ErrorBoundary fallback={<ErrorPage />}>
                <Suspense fallback={<Spinner />}>
                  <Browse sidebarIsOpen={sidebarIsOpen} />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/about"
            element={
              <ErrorBoundary fallback={<ErrorPage />}>
                <Suspense fallback={<Spinner />}>
                  <About />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/browse/mapping-tools"
            element={
              <ErrorBoundary fallback={<ErrorPage />}>
                <Suspense fallback={<Spinner />}>
                  <MappingTools />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/examples/*"
            element={
              <ErrorBoundary fallback={<ErrorPage />}>
                <Suspense fallback={<Spinner />}>
                  <Examples />
                </Suspense>
              </ErrorBoundary>
            }
          />
        </Routes>
      </div>
    </Layout>
  );
}

function Error({error}) {
  return (
    <div style={{color: 'white', margin: '2rem'}}>
      <h1>Application Error</h1>
      <pre style={{whiteSpace: 'pre-wrap', padding: '1rem'}}>{error.stack}</pre>
    </div>
  );
}
