import {dcelems} from 'lincd-dcmi/lib/ontologies/dc';
import {dcterms} from 'lincd-dcmi/lib/ontologies/dcterms';
import {lincd} from 'lincd-modules/lib/ontologies/lincd';
import {Component} from 'lincd-modules/lib/shapes/Component';
import {Module} from 'lincd-modules/lib/shapes/Module';
import {OntologyFile} from 'lincd-modules/lib/shapes/OntologyFile';
import {ShapeClass} from 'lincd-modules/lib/shapes/ShapeClass';
import {npm} from 'lincd-npm/lib/ontologies/npm';
import {Ontology} from 'lincd-owl/lib/shapes/Ontology';
import {rdfs} from 'lincd-rdfs/lib/ontologies/rdfs';
import {Shape} from 'lincd/lib/shapes/Shape';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import {fetchRegistry} from '../hooks/registry';

class MenuOption {
  label: string;
  navigate: () => void;
}

export function SearchBar() {
  let navigate = useNavigate();

  let processChange = debounce((value, callback) => searchRegistry(value, navigate).then((res) => callback(res)));

  let loadingMessage = (searchBar) => `Searching for '${searchBar.inputValue}'`;

  let noOptionsMessage = (searchBar) =>
    searchBar.inputValue.length == 0
      ? 'Start typing to search the registry!'
      : `No results found for '${searchBar.inputValue}'`;

  let onChange = (option: MenuOption) => {
    if (option) {
      option.navigate();
    }
  };

  let onKeyDown = (e) => {
    let target = e.target as HTMLInputElement;
    switch (e.key) {
      case 'Home':
        e.preventDefault();
        if (e.shiftKey) {
          target.selectionStart = 0;
        } else {
          target.setSelectionRange(0, 0);
        }
        break;
      case 'End':
        e.preventDefault();
        const len = target.value.length;
        if (e.shiftKey) {
          target.selectionEnd = len;
        } else {
          target.setSelectionRange(len, len);
        }
        break;
    }
  };

  let styles = {
    container: (provided) => ({
      ...provided,
      borderRadius: '4px',
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderWidth: '0',
      cursor: 'text',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#1e1f26',
      padding: '0 1% 0 0',
      margin: '0 1% 0 0',
      width: 'calc(100% - 50px)',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: 'montserrat',
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',
      fontFamily: 'montserrat',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected || state.isFocused ? '#5a5f72' : '#1e1f26',
    }),
  };

  return (
    <div className="search-bar">
      <AsyncSelect
        cacheOptions
        autoFocus={false}
        classNamePrefix={'lincd'}
        id="lincd-search"
        loadingMessage={loadingMessage}
        noOptionsMessage={noOptionsMessage}
        loadOptions={processChange}
        placeholder="Search LINCD..."
        onChange={onChange}
        controlShouldRenderValue={false}
        onKeyDown={onKeyDown}
        styles={styles}
        value={null}
      />
      <img src="/images/search.svg" alt="Search" />
    </div>
  );
}

function debounce(func, timeout = 700) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

function searchRegistry(input: string, navigate): Promise<MenuOption[]> {
  let searchTypes = [lincd.OntologyFile, lincd.Module, lincd.Component, lincd.ShapeClass];
  let results: Promise<MenuOption[]>;

  let defaultOption = new MenuOption();
  defaultOption.label = 'Go to results...';
  defaultOption.navigate = () => navigate(`/browse?q=${input}`);

  results = fetchRegistry(searchTypes, input).then(({instances, registryURLs}) => {
    if (window.location) {
      let tempResults: MenuOption[] = [defaultOption];

      tempResults.push(
        ...instances.map((instance) => {
          let option: MenuOption = new MenuOption();
          let resultShape: Module | OntologyFile | Component | ShapeClass;
          let resultType: 'Module' | 'Ontology' | 'Component' | 'Shape';

          option.label =
            instance.getValue(dcelems.title) ||
            instance.getValue(dcterms.title) ||
            instance.getValue(lincd.prefix) ||
            instance.getValue(rdfs.label) ||
            instance.getValue(npm.packageName) ||
            instance.getValue(lincd.npmPath);

          resultShape = Shape.getInstanceByType(instance, Module, OntologyFile, Component, Ontology, ShapeClass) as
            | Module
            | OntologyFile
            | Component
            | ShapeClass;

          if (resultShape instanceof OntologyFile) {
            resultType = 'Ontology';
          } else if (resultShape instanceof Component) {
            resultType = 'Component';
          } else if (resultShape instanceof Module) {
            resultType = 'Module';
          } else resultType = 'Shape';

          option.navigate = () => {
            // Finding corresponding index of the current instance in the registryURLs
            // array, since they will be in the same order
            navigate(registryURLs[[...instances].indexOf(instance)]);
          };

          option.label = `${resultType} | ${option.label}`;

          return option;
        }),
      );

      return tempResults;
    }
  });
  return results;
}
