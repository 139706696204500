"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.JSONLDWriter = void 0;
/*
 * This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/.
 */
const jsonld_1 = require("jsonld");
const models_1 = require("lincd/lib/models");
const QuadSet_1 = require("lincd/lib/collections/QuadSet");
const Prefix_1 = require("lincd/lib/utils/Prefix");
const NQuads_1 = require("lincd/lib/utils/NQuads");
const package_1 = require("../package");
const Shape_1 = require("lincd/lib/shapes/Shape");
const QuadArray_1 = require("lincd/lib/collections/QuadArray");
const NodeSet_1 = require("lincd/lib/collections/NodeSet");
let JSONLDWriter = class JSONLDWriter {
    static stringify(object) {
        return this.toJSONObject(object).then(JSON.stringify);
    }
    static toJSONObject(object) {
        if (object instanceof QuadSet_1.QuadSet) {
            return this.fromQuads(object);
        }
        else if (object instanceof models_1.NamedNode || object instanceof Shape_1.Shape) {
            return this.fromQuads(this.addNodeQuads(object));
        }
        else if (object instanceof models_1.Graph) {
            return this.fromGraph(object);
        }
        else if (object instanceof NodeSet_1.NodeSet) {
            return this.fromQuads(this.getNodeSetQuads(object));
        }
        else if (object.forEach) {
            //so it must be an ICoreIterable (like an array or a Set) of things
            //we'll try to convert each item
            return this.fromQuads(this.getIterableQuads(object));
        }
        else {
            throw Error('Invalid argument provided. Not sure how to convert this to JSONLD');
            // return this.fromGraphs(object);
        }
    }
    static addNodeQuads(node, quads) {
        //let quads: QuadArray | Quad[] = node.getAllQuads();
        let nodeQuads = node.getAllQuads();
        for (let quad of nodeQuads) {
            //TODO: we had to introduce this check, but its slow. So switch to using QuadSets. Remove QuadArray from library. that would speed this up a lot
            if (quads.indexOf(quad) === -1) {
                quads.push(quad);
                if (quad.object instanceof models_1.BlankNode) {
                    this.addNodeQuads(quad.object, quads);
                }
            }
        }
        return quads;
    }
    static getIterableQuads(iterable) {
        let quads = new QuadArray_1.QuadArray();
        iterable.forEach((item) => {
            if (item instanceof models_1.Graph) {
                quads = quads.concat([...item.getContents()]);
            }
            else if (item instanceof models_1.Quad) {
                quads.push(item);
            }
            else if (item instanceof models_1.Node || item instanceof Shape_1.Shape) {
                quads = this.addNodeQuads(item, quads);
            }
            else if (item['forEach']) {
                quads = quads.concat(this.getIterableQuads(item));
            }
        });
        return quads;
    }
    static getNodeSetQuads(nodeSet) {
        let quads = new QuadArray_1.QuadArray();
        nodeSet.forEach((item) => {
            quads = quads.concat(this.addNodeQuads(item, quads));
        });
        return quads;
    }
    static fromGraph(graph) {
        return this.fromGraphs([graph]);
    }
    static fromGraphs(graphs, compact = true, extraContext) {
        var nquads = NQuads_1.NQuads.fromGraphs(graphs);
        return this.NQuadsToJSONLD(nquads, compact, extraContext);
    }
    static fromQuads(quads, compact = true, extraContext) {
        //NOTE: sometimes we want to send temporary uri's, like when finding the right permanent URI for a temporary URI!
        //NOTE: in other cases this may cause issues though. So this can be turned on when needing to debug when temporary URI's get converted to JSONLD
        // let quad;
        // if (
        //   (quad = quads.find(
        //     (q) =>
        //       q.subject.isTemporaryNode ||
        //       q.predicate.isTemporaryNode ||
        //       (q.object instanceof NamedNode && q.object.isTemporaryNode),
        //   ))
        // ) {
        //   console.warn(
        //     'You are converting nodes with a temporary URI to JSONLD. You should probably give these nodes an explicit URI first as their URI will be meaningless outside of the current runtime. Example quad: ' +
        //       quad.toString(),
        //   );
        // }
        var nquads = NQuads_1.NQuads.fromQuads(quads);
        // console.log('#####', nquads);
        return this.NQuadsToJSONLD(nquads, compact, extraContext);
    }
    static NQuadsToJSONLD(nquads, compact = true, extraContext) {
        var context = compact ? this.getContext(nquads, extraContext) : null;
        return (jsonld_1.promises
            .fromRDF(nquads, { format: 'application/nquads' })
            .then((doc) => {
            if (compact) {
                return jsonld_1.promises.compact(doc, context);
            }
            return doc;
        })
            // .then(JSON.stringify)
            .catch((e) => {
            throw new Error('Could not convert quads to JSONLD. ' + e.message + `\n\nQuads in question:\n${nquads}`);
            // console.warn('Error converting triples to JSONLD.');
            // console.log(nquads);
            // throw e;
        }));
    }
    static getContext(nquads, extraContext) {
        if (!nquads)
            return {};
        var context = {};
        var prefixes = Prefix_1.Prefix.getPrefixToUriMap();
        //for each mapping from a fullURI to a prefix
        prefixes.forEach((fullURI, prefix) => {
            //if this fullURI occurs in the n-quads string
            if (nquads.indexOf(fullURI) !== -1) {
                //then we can use its prefix
                context[prefix] = fullURI;
            }
        });
        return extraContext ? Object.assign(Object.assign({}, context), extraContext) : context;
    }
};
JSONLDWriter = __decorate([
    package_1.linkedUtil
], JSONLDWriter);
exports.JSONLDWriter = JSONLDWriter;
