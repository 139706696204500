"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.JSONParser = void 0;
/*
 * This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/.
 */
const NodeURIMappings_1 = require("lincd/lib/collections/NodeURIMappings");
const CoreMap_1 = require("lincd/lib/collections/CoreMap");
const models_1 = require("lincd/lib/models");
const NodeSet_1 = require("lincd/lib/collections/NodeSet");
const CoreSet_1 = require("lincd/lib/collections/CoreSet");
const JSONLD_1 = require("./JSONLD");
const package_1 = require("../package");
let JSONParser = class JSONParser {
    static parse(json, 
    // markSubjectsAsLoaded: boolean = false,
    nodemap = new NodeURIMappings_1.NodeURIMappings()) {
        try {
            var object = JSON.parse(json);
        }
        catch (error) {
            console.warn('Error parsing json: ');
            console.trace(error.message);
        }
        return this.parseObject(object, nodemap);
    }
    static parseObject(object, 
    // markSubjectsAsLoaded: boolean = false,
    nodemap = new NodeURIMappings_1.NodeURIMappings()) {
        if ((object instanceof Object && object !== null) || object instanceof Array) {
            //update URI's before anything else
            if ('__map' in object) {
                var mappings = JSON.parse(object['__map']);
                this.applyURIMappings(mappings);
                delete object['__map'];
            }
            //process data, which are quads
            if ('__data' in object) {
                var dataPromise = JSONLD_1.JSONLD.parse(object['__data'], true, nodemap);
                delete object['__data'];
            }
        }
        var contentPromise = this.parseObjectInternal(object, nodemap);
        //make sure both the actual content-object is done AND its data
        return Promise.all([dataPromise, contentPromise]).then(([dataResult, contentResult]) => {
            //and then only return the content were interested in (which has its data embedded)
            return contentResult;
        });
    }
    static parseObjectInternal(object, nodemap) {
        if (typeof object === 'string' || typeof object === 'number' || typeof object === 'boolean') {
            return Promise.resolve(object);
        }
        else if ((object instanceof Object && object !== null) || object instanceof Array) {
            if ('__type' in object) {
                var type = object['__type'];
                if (type == 'ns') {
                    return this.createNodeSet(object, nodemap);
                }
                if (type == 'is') {
                    return this.createInstanceSet(object, nodemap);
                }
                if (type == 'cs') {
                    return this.createCoreSet(object, nodemap);
                }
                if (type == 'cm') {
                    return this.createCoreMap(object, nodemap);
                }
                if (type == 'qd') {
                    return this.createQuadSetData(object, nodemap);
                }
            }
            else if ('__t' in object) {
                return Promise.resolve(this.createQuad(object, nodemap));
            }
            else if ('__i' in object) {
                return Promise.resolve(this.createInstance(object, nodemap));
            }
            else if ('__b' in object) {
                return Promise.resolve(this.createBlankNode(object, nodemap));
            }
            else if ('__u' in object) {
                return Promise.resolve(this.createNamedNode(object, nodemap));
            }
            else if ('__l' in object) {
                return Promise.resolve(this.createLiteral(object));
            }
            else if ('__g' in object) {
                return this.createGraph(object, nodemap);
            }
            else if ('__qs' in object) {
                return this.createQuadSet(object, nodemap);
            }
            else if ('__n' in object) {
                return this.parseObjectInternal(object['__n'], nodemap);
            } //if('__n' in object)
            else {
                // var actualObject = object['__n'];
                var valuePromises = [];
                var setValue = (k, v) => {
                    object[k] = v;
                };
                for (let key in object) {
                    valuePromises.push(this.parseObjectInternal(object[key], nodemap).then(setValue.bind(this, key)));
                    // object[key] = this.parseObject(object[key]);
                }
                return Promise.all(valuePromises)
                    .then(() => {
                    return object;
                })
                    .catch((e) => {
                    console.warn('Error in parsing object keys');
                    throw e;
                });
            }
            // else
            // {
            // 	throw new Error("Unknown content type. Only parse objects that were created by JSONWriter");
            // 	// return object;
            // }
        }
        else if (!object) {
            return Promise.resolve(null);
        }
        else {
            throw Error('Unable to parse this object: ' + object.toString());
        }
    }
    static createQuad(object, nodemap) {
        var [subjectUri, predicateUri, objectString, graphString] = object['__t'];
        var subject = this.createNamedNodeFromString(subjectUri, nodemap);
        var predicate = this.createNamedNodeFromString(predicateUri, nodemap);
        var objectNode = this.createNodeFromString(objectString, nodemap);
        var graphNode = this.createNodeFromString(graphString, nodemap);
        var graph = models_1.Graph.getOrCreate(graphNode.value);
        return models_1.Quad.getOrCreate(subject, predicate, objectNode, graph);
    }
    static createNodeFromString(str, nodemap) {
        if (str.substr(0, 1) == '"') {
            return models_1.Literal.fromString(str);
        }
        return this.createNamedNodeFromString(str, nodemap);
    }
    static createNamedNodeFromString(str, nodemap) {
        //TODO: move this check into general "nodemap" method
        if (str.substr(0, 1) == '_') {
            return nodemap.getNamedNodeOrCreateBlankNode(str);
        }
        return nodemap.getOrCreateNamedNode(str);
    }
    static createInstance(object, nodemap) {
        throw new Error('Outdated. Support for SHAPES still needs to be added');
        // var node = this.createNamedNodeFromString(object['u'], nodemap);
        // var instanceType = this.createNamedNodeFromString(object['__i'], nodemap);
        // return Shape.getFromClass(node, instanceType);
    }
    static createNamedNode(object, nodemap) {
        return nodemap.getOrCreateNamedNode(object['__u']);
    }
    static createBlankNode(object, nodemap) {
        return nodemap.getNamedNodeOrCreateBlankNode(object['__b']);
    }
    static createLiteral(object) {
        return models_1.Literal.fromString(object['__l']);
    }
    static createInstanceSet(object, nodemap) {
        throw new Error('Converting ShapeSets to JSON is not yet supported');
        // return this.createCoreSet(object, nodemap, new ShapeSet<Shape>()) as Promise<
        // 	ShapeSet<Shape>
        // >;
    }
    static createNodeSet(object, nodemap) {
        return this.createCoreSet(object, nodemap, new NodeSet_1.NodeSet());
    }
    static applyURIMappings(uriMappings) {
        if (uriMappings && Array.isArray(uriMappings)) {
            uriMappings.forEach(([oldUri, newUri]) => {
                var resource = models_1.NamedNode.getNamedNode(oldUri);
                //we check if a node with the old uri exists, because its uri may already have been updated through other channels (like sockets)
                if (resource) {
                    throw Error('Updating uris through JSON needs to be reimplemented');
                    // NamedNode.updateUri(resource, newUri);
                }
            });
            return true;
        }
        return false;
    }
    static createCoreSet(object, nodemap, resultSet = new CoreSet_1.CoreSet()) {
        var entryPromises = [];
        let add = resultSet.add.bind(resultSet);
        object.entries.forEach((entry) => {
            entryPromises.push(this.parseObjectInternal(entry, nodemap).then(add));
        });
        return Promise.all(entryPromises).then(() => {
            return resultSet;
        });
    }
    static createCoreMap(object, nodemap) {
        var res = new CoreMap_1.CoreMap();
        var entryPromises = [];
        object.entries.forEach((entry) => {
            entryPromises.push(this.parseObjectInternal(entry, nodemap).then(([key, value]) => {
                res.set(key, value);
            }));
        });
        return Promise.all(entryPromises).then(() => res);
    }
    static createGraph(object, nodemap) {
        if (object.content) {
            return JSONLD_1.JSONLD.parse(object.content, true, nodemap).then((parseResult) => {
                var graph = models_1.Graph.getOrCreate(object.__g);
                // graph.setContents(parseResult.quads);
                return graph;
            });
        }
        else {
            return Promise.resolve(models_1.Graph.getOrCreate(object.__g));
        }
    }
    static createQuadSet(object, nodemap) {
        return JSONLD_1.JSONLD.parse(object['__qs'], true, nodemap).then((parseResult) => {
            return parseResult.quads;
        });
    }
    static createQuadSetData(object, nodemap) {
        return Promise.resolve(object.entries);
    }
};
JSONParser = __decorate([
    package_1.linkedUtil
], JSONParser);
exports.JSONParser = JSONParser;
