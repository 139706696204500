import NavBar from './NavBar';
import {useEffect, useTransition} from 'react';
import Sidebar from './Sidebar';
import {Link, useMatch, useNavigate} from 'react-router-dom';
import {useWindowSize} from './hooks/useWindowSize';
import {SearchBar} from './components/SearchBar';
import './scss/footer.scss';

export default function Layout({children, sidebarIsOpen, setSidebarIsOpen}) {
  let isBrowseMenu = useMatch('/browse/*');
  let isHome = useMatch('/');

  //sidebar will behave differently from this size, see scss
  let sidebarBreakpoint = 1023;

  let adjustContentWidthBasedOnSidebar = function (
    width = typeof window !== 'undefined' ? window.innerWidth : undefined,
  ) {
    return typeof width && width >= sidebarBreakpoint;
  };

  let {width} = useWindowSize();
  const [isPending, startTransition] = useTransition();
  useEffect(() => {
    startTransition(() => setSidebarIsOpen(adjustContentWidthBasedOnSidebar(width)));
  }, [width]);

  let navigate = useNavigate();

  let year = new Date().getFullYear();

  return (
    <>
      <div className="wrapper landing-page">
        <div className="dashboard">
          <header>
            <div className="header-left-nav">
              <div className="logo">
                <Link to="/">
                  <svg width="188" height="75" viewBox="0 0 188 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M42.8816 42.5432C40.0885 39.7501 40.0885 35.2216 42.8816 32.4285C44.0122 31.2978 45.4212 30.627 46.8877 30.4107L59.3072 30.3669C60.9024 30.5206 62.4509 31.2063 63.673 32.4285C66.4662 35.2216 66.4662 39.7501 63.673 42.5432C62.4493 43.767 60.8981 44.453 59.3007 44.6054L51.4161 44.6243C51.2064 44.8745 50.9839 45.1176 50.7486 45.3529C50.0969 46.0046 49.384 46.5587 48.6285 47.015L59.4091 46.9893C61.5789 46.8092 63.6988 45.8891 65.3588 44.229C69.083 40.5049 69.083 34.4668 65.3588 30.7427C63.6988 29.0826 61.5789 28.1625 59.4092 27.9824L46.7209 28.0272C44.7007 28.2859 42.7474 29.1911 41.1958 30.7427C37.8509 34.0876 37.5103 39.2991 40.1739 43.0242L43.4029 43.0164C43.2235 42.8688 43.0494 42.7111 42.8816 42.5432Z"
                      fill="#02ABF2"
                    />
                    <path
                      d="M27.1469 42.5432C24.3538 39.7501 24.3538 35.2216 27.1469 32.4284C28.2775 31.2978 29.6865 30.627 31.153 30.4107L39.3752 30.3817C39.5933 30.1194 39.8253 29.8648 40.0713 29.6188C40.7082 28.9819 41.4036 28.4382 42.1402 27.9878L30.9862 28.0272C28.966 28.2859 27.0127 29.191 25.4611 30.7427C21.7369 34.4668 21.7369 40.5048 25.4611 44.229C27.2463 46.0142 29.5632 46.9436 31.902 47.0173L43.6744 46.9892C45.8442 46.8092 47.964 45.8891 49.6241 44.229C52.9573 40.8958 53.3072 35.7091 50.6739 31.9867L47.4685 31.998C47.6298 32.1332 47.7866 32.2767 47.9383 32.4284C50.7314 35.2216 50.7314 39.7501 47.9383 42.5432C46.7145 43.767 45.1634 44.453 43.566 44.6054L31.9396 44.6332C30.1955 44.5691 28.4771 43.8734 27.1469 42.5432Z"
                      fill="white"
                    />
                    <path
                      d="M94.6652 45.9609H85.6551C85.34 45.9609 85.1824 45.8034 85.1824 45.4882V28.5527C85.1824 28.2375 85.34 28.0799 85.6551 28.0799H87.4071C87.7222 28.0799 87.8798 28.2375 87.8798 28.5527V43.2635C87.8798 43.4489 87.9911 43.5416 88.2135 43.5416H94.6652C94.9803 43.5416 95.1379 43.7084 95.1379 44.0421V45.4882C95.1379 45.8034 94.9803 45.9609 94.6652 45.9609ZM104.431 28.5527V45.4882C104.431 45.8034 104.274 45.9609 103.959 45.9609H102.207C101.892 45.9609 101.734 45.8034 101.734 45.4882V28.5527C101.734 28.2375 101.892 28.0799 102.207 28.0799H103.959C104.274 28.0799 104.431 28.2375 104.431 28.5527ZM114.719 45.9609H112.995C112.679 45.9609 112.522 45.8034 112.522 45.4882V28.5527C112.522 28.2375 112.679 28.0799 112.995 28.0799H114.552C114.848 28.0799 115.052 28.1819 115.164 28.3858L121.893 40.4826H122.005V28.5527C122.005 28.2375 122.162 28.0799 122.477 28.0799H124.201C124.517 28.0799 124.674 28.2375 124.674 28.5527V45.4882C124.674 45.8034 124.517 45.9609 124.201 45.9609H122.7C122.403 45.9609 122.162 45.8126 121.977 45.516L115.303 33.5582H115.191V45.4882C115.191 45.8034 115.034 45.9609 114.719 45.9609ZM139.886 45.9609H136.66C135.288 45.9609 134.222 45.5809 133.462 44.8208C132.72 44.0607 132.35 43.0039 132.35 41.6506V32.3903C132.35 31.0184 132.72 29.9617 133.462 29.2201C134.222 28.46 135.288 28.0799 136.66 28.0799H139.886C141.239 28.0799 142.296 28.46 143.056 29.2201C143.816 29.9617 144.196 31.0184 144.196 32.3903V33.7251C144.196 34.0588 144.029 34.2257 143.696 34.2257H141.944C141.629 34.2257 141.471 34.0588 141.471 33.7251V32.5015C141.471 31.1296 140.804 30.4437 139.469 30.4437H137.049C135.715 30.4437 135.047 31.1296 135.047 32.5015V41.5394C135.047 42.9112 135.715 43.5972 137.049 43.5972H139.469C140.804 43.5972 141.471 42.9112 141.471 41.5394V40.3158C141.471 39.9821 141.629 39.8152 141.944 39.8152H143.696C144.029 39.8152 144.196 39.9821 144.196 40.3158V41.6506C144.196 43.0039 143.816 44.0607 143.056 44.8208C142.296 45.5809 141.239 45.9609 139.886 45.9609Z"
                      fill="white"
                    />
                    <path
                      d="M154.542 43.5972H158.463C159.798 43.5972 160.465 42.9112 160.465 41.5394V32.5015C160.465 31.1296 159.798 30.4437 158.463 30.4437H154.542C154.338 30.4437 154.236 30.5364 154.236 30.7218V43.2913C154.236 43.4952 154.338 43.5972 154.542 43.5972ZM151.539 45.4882V28.5527C151.539 28.2375 151.696 28.0799 152.012 28.0799H158.852C160.206 28.0799 161.263 28.46 162.023 29.2201C162.801 29.9617 163.191 31.0184 163.191 32.3903V41.6506C163.191 43.0039 162.801 44.0607 162.023 44.8208C161.263 45.5809 160.206 45.9609 158.852 45.9609H152.012C151.696 45.9609 151.539 45.8034 151.539 45.4882Z"
                      fill="#02ABF2"
                    />
                  </svg>
                </Link>
              </div>
              {isHome && (
                <>
                  <div className="menu min-w-screen-tablet">
                    <Link to="/browse">
                      <span>Browse</span>
                    </Link>
                    <Link to="/examples">
                      <span>Examples</span>
                    </Link>
                    <a href="https://docs.lincd.org/">
                      <span>Documentation</span>
                    </a>
                  </div>
                </>
              )}
            </div>

            <div className="header-right-nav">
              <SearchBar />
              <a href="https://discord.gg/xsQ5PQWGka">
                <button className="landing-page-button header-join-us">Join us</button>
              </a>
              {/* <button className="header-login">Log in</button>
              <button className="header-register">Register</button> */}
              <div className="burger-menu">
                <svg
                  onClick={() => {
                    setSidebarIsOpen(!sidebarIsOpen);
                  }}
                  width="22"
                  height="14"
                  viewBox="0 0 22 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 1H11" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                  <path d="M11 13H21" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                  <path d="M1 7H21" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </div>
            </div>
          </header>
        </div>
      </div>
      <div className={'sidenav-container max-w-screen-medium ' + (isHome ? 'isHome' : '')}>
        <aside className={'sidenav ' + (sidebarIsOpen ? 'active-aside' : 'closed')}>
          <Sidebar
            setSidebarIsOpen={setSidebarIsOpen}
            sidebarIsOpen={sidebarIsOpen}
            sidebarBreakpoint={sidebarBreakpoint}
          />
        </aside>
      </div>
      <main className={sidebarIsOpen && !isHome ? 'open-sidebar' : ''}>
        {children}
        <footer className="footer">
          <div className="footer-a">
            <div className="footerGroup">
              <div className="footerGrid">
                <div className="logo-icon-lincd">
                  <img className="icon-lincd" src="/images/Icon_LINCD.svg" />
                  <div className="lincd">
                    LINC<span>D</span>
                  </div>
                </div>
                <div className="footerListGroup browse">
                  <div className="list-head-1" onClick={() => navigate('/browse')}>
                    Browse
                  </div>
                  <div className="footerlist">
                    <div className="list-item-1" onClick={() => navigate('/browse')}>
                      Ontologies
                    </div>
                    <div className="list-item-1" onClick={() => navigate('/browse')}>
                      Components
                    </div>
                    <div className="list-item-1" onClick={() => navigate('/browse')}>
                      Packages
                    </div>
                    <div className="list-item-1" onClick={() => navigate('/browse')}>
                      Shapes
                    </div>
                  </div>
                </div>
                <div className="footerListGroup community">
                  <div className="list-head-1">Community</div>
                  <div className="footerlist">
                    <div className="list-item-1" onClick={() => window.open('https://smtu.link/discord', '_blank')}>
                      Discord
                    </div>
                    <div
                      className="list-item-1"
                      onClick={() => window.open('https://www.linkedin.com/company/89239511/', '_blank')}
                    >
                      LinkedIn
                    </div>
                    <div className="list-item-1" onClick={() => window.open('https://twitter.com/LINCD_org', '_blank')}>
                      Twitter
                    </div>
                  </div>
                </div>
                <div className="footerListGroup company">
                  <div className="list-head-1">Company</div>
                  <div className="footerlist">
                    <div className="list-item-1">About us</div>
                    <div className="list-item-1">Contact us</div>
                    <div className="list-item-1">History</div>
                  </div>
                </div>
                <div className="loginbuttons">
                  <div className="keybutton">
                    <a href="https://discord.gg/xsQ5PQWGka">
                      <button className="rectangle-2">Join us</button>
                    </a>
                  </div>
                  {/* <div className="keybutton">
                      <div className="rectangle-2">Register</div>
                    </div>
                    <div className="login">
                      <div className="log-in">Log in</div>
                    </div> */}
                </div>
              </div>
              <div className="frame-36654">
                <div className="rectangle"></div>
                <div className="frame-36650">
                  <div className="lincd-inc-2022-we-love-our-users">© LINCD Inc. {year}. We love our users!</div>
                  <div className="frame-36648">
                    <div className="follow-us">Follow us:</div>
                    <div className="frame-4">
                      <div className="linkedin">
                        <a href="https://www.linkedin.com/company/89239511/" title={'LinkedIn'}>
                          <img src="/images/LinkedIn.svg" />
                        </a>
                      </div>
                      <div className="github">
                        <a href="https://github.com/Semantu/lincd" title={'GitHub'}>
                          <img src="/images/github.svg" />
                        </a>
                      </div>
                      <div className="twitter-2">
                        <a href="https://twitter.com/LINCD_org" title={'Twitter'}>
                          <img src="/images/twitter.svg" />
                        </a>
                      </div>
                      <div className="discord-2">
                        <a href="https://discord.gg/xsQ5PQWGka" title={'Discord'}>
                          <img src="/images/discord.svg" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </main>
    </>
  );
}
